// We need jQuery
var $ = window.jQuery;

import GoogleMapsLoader from 'google-maps';

//GoogleMapsLoader.KEY = tofinoJS.googleMapsAPIKey;
GoogleMapsLoader.KEY = 'AIzaSyAL6wt4DoZS6YLK4bNrYTIN8H4oQbHl358';
GoogleMapsLoader.VERSION = '3.36';

// Import flickity
import Flickity from 'flickity';

// Import jQuery Bridget
var jQueryBridget = require('jquery-bridget');

export default () => {
  // make Flickity a jQuery plugin
  jQueryBridget('flickity', Flickity, $);

  // Open panel based on user's IP
  $('.js-load-office').each(function () {
    var $parent = $(this).parent('[data-location-list-group]');
    var regionId = $(this).data('region');

    // Activate location
    if ($parent.hasClass('is-expanded')) {
      $parent.removeClass('is-expanded');
    } else {
      $('[data-location-list-group]').removeClass('is-expanded');
      $parent.addClass('is-expanded');
    }
    $(this).addClass('is-active');
    $('#' + regionId).addClass('is-visible');
    initItems(regionId);
  });

  // Show/Hide Locations List on Mobile
  $('[data-location-list-toggle]').click(function () {
    var $parent = $(this).parent('[data-location-list-group]');

    if ($parent.hasClass('is-expanded')) {
      $parent.removeClass('is-expanded');
    } else {
      $('[data-location-list-group]').removeClass('is-expanded');
      $parent.addClass('is-expanded');
    }
  });

  // Show/Hide Panel
  $('[data-location-tab]').click(function () {
    var regionId = $(this).data('region');

    // Remove Active States
    $('[data-location-tab]').removeClass('is-active');
    $('[data-location-panel]').removeClass('is-visible');

    // Add Active States
    $(this).addClass('is-active');
    $('#' + regionId).addClass('is-visible');

    // Scroll to element
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('#' + regionId).offset().top - 100,
      },
      300
    );

    initItems(regionId);
  });

  if (document.querySelector('.module-get-in-touch')) {
    initItems(0);
  }

  function initItems (regionId) {
    $('#' + regionId + ' .flickity-carousel').flickity({
      cellAlign: 'left',
      groupCells: true,
      resize: true,
      contain: true,
      pageDots: false,
    });

    if ($('#google-map-single-office-' + regionId).length) {
      var $elm = $('#google-map-single-office-' + regionId);

      GoogleMapsLoader.load(function (google) {
        var latitude = parseFloat($elm.data('latitude')),
          longitude = parseFloat($elm.data('longitude')),
          title = $elm.data('title'),
          LatLng = new google.maps.LatLng(latitude, longitude);

        var map = new google.maps.Map(
          document.getElementById('google-map-single-office-' + regionId),
          {
            zoom: 9,
            center: LatLng,
            disableDefaultUI: false,
            backgroundColor: '#fcfcfc',
            scrollwheel: false,
            draggable: true,
            fullscreenControl: false,
            fullscreenControlOptions: true,
            rotateControl: true,
            rotateControlOptions: true,
            tilt: 45,
            styles: [],
          }
        );

        var marker = new google.maps.Marker({
          position: LatLng,
          map: map,
          title: title,
        });

        marker.setMap(map);

        map.panTo(LatLng); // Set center

        // Create info window instance
        var infowindow = new google.maps.InfoWindow();

        google.maps.event.addListener(
          marker,
          'click',
          (function (infowindow) {
            return function () {
              infowindow.setContent(
                this.title +
                  '<br><br><a href="https://www.google.com/maps/dir/Current+Location/' +
                  latitude +
                  ',' +
                  longitude +
                  '" target="_blank">Directions</a>'
              );
              infowindow.open(map, this);
            };
          })(infowindow)
        );
      });
    }
  }
};
