import Vue from 'vue';

export default {
  init() {
    new Vue({
      el: '#newsroom',
      data: {
        postUrl: '/wp-json/investinholland/v1/newsroom',
        posts: [],
        postArgs: {
          per_page: 11,
          page: 1,
          offset: null,
          post_types: [],
          categories: [],
          key_industries: '',
          business_operations: '',
        },
        pagination: {
          prevPage: '',
          nextPage: '',
          totalPages: '',
          from: '',
          to: '',
          total: '',
        },
        isLoading: true,
        loadingMorePosts: false,
        // loadMoreText: "Load More",
        pageUrl: window.location.href,
        hasFeaturedPost: true,
      },
      mounted: function () {
        this.setPostArgs();
        this.fetchPostData();
      },
      methods: {
        fetchPostData: function (loadingMore) {
          if (loadingMore) {
            this.postArgs.per_page = 6;
            this.postArgs.offset = this.posts.length;
          } else {
            this.isLoading = true;
            this.postArgs.per_page = 11;
            this.posts = [];
          }

          this.loadingMorePosts = true;
          // this.loadMoreText = "Loading...";

          fetch(this.postUrl + '?' + new URLSearchParams(this.postArgs))
            .then(async (response) => {
              const data = await response.json();

              if (this.postArgs.page > 1) {
                this.posts.push.apply(this.posts, data);
              } else {
                // Assign reponse data to posts variable
                this.posts = data;
              }

              this.configPagination(response.headers);

              this.isLoading = false;

              this.loadingMorePosts = false;
            })
            .catch((error) => {
              console.log(error);

              this.loadingMorePosts = false;
            });
        },
        configPagination: function (headers) {
          this.pagination.totalPages = +headers.get('x-wp-totalpages');
          this.pagination.total = +headers.get('x-wp-total');
          this.pagination.nextPage =
            this.postArgs.page < this.pagination.totalPages ? this.postArgs.page + 1 : null;
        },
        updateFilters: function (e) {
          var button = e.currentTarget;
          var categoryId = e.currentTarget.getAttribute('data-category');
          var postType = e.currentTarget.getAttribute('data-post-type');
          this.removeActiveClassOnButtons();
          this.postArgs.page = 1;

          if (postType == 'success_stories') {
            this.postArgs.categories = [];
            this.postArgs.post_types = ['success_stories'];

            if (!button.classList.contains('is-active')) {
              button.classList.add('is-active');
            }
          } else {
            this.postArgs.post_types = ['post'];
            this.postArgs.categories = [];

            if (!button.classList.contains('is-active')) {
              button.classList.add('is-active');
              this.postArgs.categories.push(categoryId);
            }
          }

          this.fetchPostData();
        },
        updateKeyIndustries: function (e) {
          this.postArgs.key_industries = e.currentTarget.value;
          this.fetchPostData();
        },
        updateBusinessOperations: function (e) {
          this.postArgs.business_operations = e.currentTarget.value;
          this.fetchPostData();
        },
        resetFilters: function () {
          this.postArgs.categories = [];
          this.postArgs.post_types = [];
          this.postArgs.key_industries = '';
          this.postArgs.business_operations = '';
          this.postArgs.page = 1;
          this.fetchPostData();
          this.resetFilterSelects();
          this.removeActiveClassOnButtons();
        },
        removeItemFromArray: function (array, value) {
          if (value == null || value == '') {
            return;
          }

          var i = 0;
          while (i < array.length) {
            if (array[i] === value) {
              array.splice(i, 1);
            } else {
              ++i;
            }
          }
          return array;
        },
        removeActiveClassOnButtons: function () {
          const buttons = document.querySelectorAll('[data-newsroom-filter-btn]');
          buttons.forEach((button) => {
            button.classList.remove('is-active');
          });
        },
        resetFilterSelects: function () {
          const selects = document.querySelectorAll('[data-newsroom-filter-select]');
          selects.forEach((select) => {
            select.selectedIndex = 0;
          });
        },
        loadMore: function () {
          this.postArgs.page++;
          this.fetchPostData(true);
        },
        setPostArgs: function () {
          var segments = this.pageUrl.split('/');

          // check of pageUrl contains a query string and remove it
          if (segments[segments.length - 1].includes('?')) {
            segments[segments.length - 1] = segments[segments.length - 1].split('?')[0];
          }

          var lastSegment = segments.pop() || segments.pop();

          console.log(lastSegment);

          switch (lastSegment) {
            case 'success-stories':
              this.postArgs.post_types = ['success_stories'];
              document
                .querySelector('[data-post-type="success_stories"]')
                .classList.add('is-active');
              break;
            case 'nfia-blog':
              this.postArgs.post_types = ['post'];
              this.postArgs.categories = ['38'];
              document.querySelector('[data-category="38"]').classList.add('is-active');
              break;
            case 'video':
              this.postArgs.post_types = ['post'];
              this.postArgs.categories = ['39'];
              document.querySelector('[data-category="39"]').classList.add('is-active');
              break;
            default:
              this.postArgs.post_types = [];
              this.postArgs.categories = [];
          }
        },
      },
    });
  },
  loaded() {
    // Javascript to be fired on page once fully loaded
  },
};
