// We need jQuery
var $ = window.jQuery;

export default {
  init () {
    // Logo shrink on scroll
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 600) {
        $('.navbar-brand').addClass('scrolled');
      } else {
        $('.navbar-brand').removeClass('scrolled');
      }
    });
  },
  loaded () {
    // Javascript to be fired on page once fully loaded
  },
};
