// We need jQuery
var $ = window.jQuery;

// Import Cookies
import Cookies from 'js-cookie';

import Contact from './contact';

// Region
import OfficeRedirect from '../office-redirect.js';
window.OfficeRedirect = OfficeRedirect;

// Layout
import UpdateLayout from '../update-layout.js';
window.UpdateLayout = UpdateLayout;

// Import High Charts
import Highcharts from 'highcharts/highcharts';

// Import Highcharts data module
import Exporting from 'highcharts/modules/exporting';

// Load/Bind that module
Exporting(Highcharts);

// Give window Highcharts
window.Highcharts = Highcharts;

// Twitter Feed
import TwitterFeed from '../twitter-feed';

// Bar of Fame
import BarOfFame from '../bar-of-fame';

export default {
  init () {
    Contact();

    // JavaScript to be fired on all pages
    document.querySelectorAll('.twitter-feed').forEach(el => {
      TwitterFeed(el);
    });

    BarOfFame();

    $('.btn-business-operations-links')
      .mouseenter(function () {
        const desc = $(this).data('text');
        $('.business-operations-links-desc')
          .first()
          .html(desc);
      })
      .mouseleave(function () {
        $('.business-operations-links-desc')
          .first()
          .html('');
      });

    $('.js-menu a, .navbar-toggler').on('click', function (e) {
      e.preventDefault();
      $('#main-menu-modal').modal('show');
    });

    $('.modal-menu-close').on('click', function (e) {
      e.preventDefault();
      $('#main-menu-modal').modal('hide');
    });

    $('.js-search a').on('click', function (e) {
      e.preventDefault();
      $('#navbarSearchbar').collapse('toggle');
      $('body').toggleClass('search-open');
    });

    // YT Video JS
    $('.js-youtube-video').on('click', function (e) {
      e.preventDefault();

      var embedUrl = $(this).attr('href');

      $('#YTVideoFrame').data('src', embedUrl);
      $('#YTVideo').modal('show');
    });

    $('#YTVideo').on('shown.bs.modal', function () {
      var addautoplay =
        $('#YTVideoFrame').data('src') +
        '?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;autoplay=1&amp;vq=hd1080';

      $('#YTVideoFrame').attr('src', addautoplay);
    });

    // Stop video on modal close
    $('#YTVideo').on('hide.bs.modal', function () {
      $('#YTVideoFrame').attr('src', '');
    });

    // List for notication close
    $('#cookies-banner .cookies-close').on('click', function (e) {
      e.preventDefault();

      $('#cookies-banner').hide();

      if (tofinoJS.cookieExpires) {
        Cookies.set('cookies-banner-closed', 'yes', {
          expires: parseInt(tofinoJS.cookieExpires),
        });
      } else {
        Cookies.set('cookies-banner-closed', 'yes');
      }
    });

    // Cookie notification close
    $('[data-notification-close]').on('click', function () {
      var id = $(this).attr('data-notification-close');
      $('[data-notification="' + id + '"]').hide();
      Cookies.set('nfia-notification-' + id + '-closed', 'yes');
    });

    // Show the notfication using JS based on the cookie
    $('[data-notification]').each(function (index) {
      if (!Cookies.get('nfia-notification-' + index + '-closed')) {
        $('[data-notification="' + index + '"]').show();
      }
    });

    // Resize Filters
    $('.success-story-filters-select').change(function () {
      var post_type = $(this).data('select-type');

      ResizeFilterSelect(post_type);

      window.location.href = this.value;
    });

    // Resize Filter Select
    function ResizeFilterSelect (post_type) {
      var selector = '.success-story-filters-select[data-select-type="' + post_type + '"]';
      $('.js-select-type-' + post_type + ' .js-select-option').html(
        $(selector + ' option:selected').text()
      );
      $(selector).width($('.js-select-type-' + post_type).width() - 20);
      $(selector).css('visibility', 'visible');
    }

    if ($('.success-story-filters-select').length) {
      $('.success-story-filters-select').each(function () {
        var post_type = $(this).data('select-type');
        ResizeFilterSelect(post_type);
      });
    }

    // move multiple carousels
    $('.module-slider .nav-pills .nav-link').click(function () {
      $('.module-slider .nav-pills .nav-link').removeClass('active');
      $(this).addClass('active');
      var number = $(this).data('slide-to');
      $('.slider-multi-slider').carousel(number);
    });

    $('.js-filter-layout').on('click', function (e) {
      e.preventDefault();

      var layout_type = $(this).data('layout-type');

      UpdateLayout(layout_type);
    });
  },
  finalize () {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
  loaded () {
    // Javascript to be fired on page once fully loaded
  },
};
