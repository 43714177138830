import Vue from 'vue';

export default el => {
  new Vue({
    name: 'TwitterFeed',
    el: el,
    data: {
      twitter_feed: {},
      tweet: '',
      time_elapsed: '',
      media_url: null,
      loadingFeed: true,
      security: tofinoJS.nextNonce,
      debug: null,
      geo: {
        ip: null,
        country: null,
        city: null,
        region: null,
      },
    },
    async mounted () {
      // const done = await this.getGeo();

      // if (done) {
      this.fetchFeed();
      // }
    },
    methods: {
      getGeo () {
        return geoip_detect.get_info().then(record => {
          if (record.error()) {
            console.error('WARNING Geodata Error:' + record.error());
          } else {
            var country = record.get('country.iso_code');
            Vue.set(this.geo, 'country', country);

            var city = record.get('city.names.en');
            Vue.set(this.geo, 'city', city);

            var ip = record.get('traits.ip_address');
            Vue.set(this.geo, 'ip', ip);

            var region = record.get('most_specific_subdivision.iso_code');
            // this.geo.region = region;

            Vue.set(this.geo, 'region', region);

            return this.geo;
          }
        });
      },
      async fetchFeed () {
        // this.loadingFeed = true;
        fetch(tofinoJS.ajaxUrl, {
          method: 'post',
          body: new URLSearchParams({
            action: 'twitter',
            security: this.nextNonce,
            data: this.geo,
          }),
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        })
          .then(async response => {
            const data = await response.json();

            this.twitter_feed = data.twitter_feed;
            this.tweet = data.tweet;
            this.time_elapsed = data.time_elapsed;
            this.media_url = data.media_url;
            this.debug = data.debug;
            this.loadingFeed = false;
          })
          .catch(error => {
            console.log(error);
            this.loadingFeed = false;
          });
      },
    },
  });
};
