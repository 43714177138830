// We need jQuery
var $ = window.jQuery;

module.exports = function UpdateLayout (layout_type) {
  console.log(layout_type);

  var request = $.post(tofinoJS.ajaxUrl, {
    action: 'update_layout', // Passed to WP for the ajax action
    layout_type: layout_type,
  });

  request.done(function (response) {
    if (response.success === true) {
      console.log(response);

      $('body').fadeOut(150);
      window.location = window.location.href;
    } else {
      console.error('An error occured changing the layout. Invalid response.');
    }
  });

  request.fail(function () {
    console.error('A request error occured changing the layout.');
  });
};
