// We need jQuery
var $ = window.jQuery;

module.exports = function OfficeRedirect (region_id) {
  var request = $.post(tofinoJS.ajaxUrl, {
    action: 'get_office_link', // Passed to WP for the ajax action
    region_id: region_id,
  });

  request.done(function (response) {
    if (response.success === true) {
      window.location = response.office_link;
    } else {
      console.error('An error occured changing the getting the office link. Invalid response.');
    }
  });

  request.fail(function () {
    console.log(request);
    console.error('A request error occured getting the office link.');
  });
};
