import Vue from 'vue';

export default {
  init () {
    new Vue({
      el: '#search',
      data: {
        postUrl: '/wp-json/investinholland/v1/search',
        posts: [],
        args: {
          search: '',
          per_page: 12,
          page: 1,
        },
        pagination: {
          prevPage: '',
          nextPage: '',
          totalPages: '',
          from: '',
          to: '',
          total: '',
        },
        isLoading: true,
        loadingMorePosts: false,
        pageUrl: window.location.href,
        hasFeaturedPost: false,
      },
      mounted () {
        this.setArgs();
        this.fetchPostData();
      },
      methods: {
        fetchPostData (loadingMore) {
          if (loadingMore) {
            this.args.per_page = 12;
            this.args.offset = this.posts.length;
          } else {
            this.isLoading = true;
          }

          this.loadingMorePosts = true;

          fetch(this.postUrl + '?' + new URLSearchParams(this.args))
            .then(async response => {
              const data = await response.json();

              if (this.args.page > 1) {
                this.posts.push.apply(this.posts, data);
              } else {
                // Assign reponse data to posts variable
                this.posts = data;
              }

              this.configPagination(response.headers);

              this.isLoading = false;

              this.loadingMorePosts = false;
            })
            .catch(error => {
              console.log(error);

              this.loadingMorePosts = false;
            });
        },
        configPagination (headers) {
          this.pagination.totalPages = +headers.get('x-wp-totalpages');
          this.pagination.total = +headers.get('x-wp-total');
          this.pagination.nextPage =
            this.args.page < this.pagination.totalPages ? this.args.page + 1 : null;
        },
        loadMore () {
          this.args.page++;
          this.fetchPostData(true);
        },
        setArgs () {
          const pathArray = window.location.pathname.split('/');

          this.args.search = pathArray[2];
        },
      },
    });
  },
  loaded () {
    // Javascript to be fired on page once fully loaded
  },
};
