import Vue from 'vue';

export default () => {
  const bar = document.getElementById('bar-fame');

  if (bar) {
    new Vue({
      el: '#bar-fame',
      data: {
        postUrl: '/wp-json/investinholland/v1/bar-fame',
        posts: [],
        postArgs: {
          per_page: 8,
          filter: '',
          stickyPosts: [],
        },
        isLoading: true,
      },
      mounted: function () {
        const wrapper = document.querySelector('#bar-fame');

        if (wrapper) {
          this.postArgs.filter = wrapper.dataset.filter;

          if (wrapper.dataset.stickyPostsIds) {
            this.postArgs.stickyPosts = wrapper.dataset.stickyPostsIds.split(',');
          }

          this.fetchPostData();
        }
      },
      methods: {
        fetchPostData () {
          fetch(this.postUrl + '?' + new URLSearchParams(this.postArgs))
            .then(async response => {
              const data = await response.json();

              this.posts = data;
            })
            .catch(error => {
              console.log(error);
            });

          this.isLoading = false;
        },
      },
    });
  }
};
